import React, { useState, useEffect } from "react";
import ReactGA from "react-ga4";
import questionGraphic from "./images/question-mark-circle-4-128.png";
import * as Yup from "yup";
import styles from "./Interview.module.css";

const validationSchema = Yup.object().shape({
  deductionType: Yup.string().required(
    "You must pick either standard or itemized deductions. Be sure to indicate the total amount to itemize in the next step."
  ),
});
export default function FilingStatus({
  deductionCloseButton,
  handleChange,
  formValues,
}) {
  const [deductionType, setDeductionType] = useState({
    deductionType: formValues.deductionType || "",
    amountToItemizeSpouse: formValues.amountToItemizeSpouse || 0,
    amountToItemize: formValues.amountToItemize || 0,
    totalAmountToItemize: formValues.totalAmountToItemize || 0,
  });
  const [validationError, setValidationError] = useState(null);

  const [open, setOpen] = useState(formValues.deductionType === "itemized");
  const [showSpouseSection, setShowSpouseSection] = useState(
    formValues.deductionType === "itemized" &&
      formValues.totalAmountToItemize !== 0
  );

  useEffect(() => {
    const primaryPercentage = incomePercentage();
    const spousePercentage = spouseIncomePercentage();
    const primaryItemizeAmount =
      (primaryPercentage / 100) * formValues.totalAmountToItemize;
    const spouseItemizeAmount =
      (spousePercentage / 100) * formValues.totalAmountToItemize;

    setDeductionType({
      deductionType: formValues.deductionType || "",
      amountToItemize: formValues.amountToItemize || primaryItemizeAmount,
      amountToItemizeSpouse:
        formValues.amountToItemizeSpouse || spouseItemizeAmount,
      totalAmountToItemize: formValues.totalAmountToItemize || 0,
    });
  }, [formValues]);

  const deductionAlert = () => {
    alert(
      "If you expect to claim deductions other than the basic standard deduction on your tax return and want to reduce your withholding to account for these deductions, enter the total amount you plan to itemize including other deductions such as for student loan interest and IRAs."
    );
  };

  const makeChange = (evt) => {
    const { name, value } = evt.target;

    setDeductionType((prevDeductionType) => ({
      ...prevDeductionType,
      [name]: value,
    }));

    if (name === "deductionType" && value === "itemized") {
      setOpen(true);
    } else if (name === "deductionType" && value === "standard") {
      setOpen(false);
      setDeductionType({
        deductionType: "standard",
        amountToItemizeSpouse: 0,
        amountToItemize: 0,
        totalAmountToItemize: 0,
      });
    }
    setValidationError(null);
  };

  const handleSubmit = async (evt) => {
    evt.preventDefault(); // Prevent form submission

    try {
      // Validate the form using Yup
      await validationSchema.validate(deductionType, { abortEarly: false });

      // If validation passes, proceed with form submission
      handleChange(deductionType); // Pass deduction object to handleChange

      ReactGA.event({
        category: "User",
        action: "Completed deduction type",
      });

      deductionCloseButton(); // Close the form or go to the next step
    } catch (error) {
      // Handle validation error
      setValidationError(error.message); // Show the error message
    }
  };

  let deductionMessage;

  if (formValues.taxFilingStatus === "married") {
    deductionMessage = (
      <p>
        The standard deduction is $29,200 if you’re married filing jointly or a
        qualifying surviving spouse. Consider using itemized deductions if your
        qualifying expenses are higher than $29,200.
      </p>
    );
  } else if (formValues.taxFilingStatus === "household") {
    deductionMessage = (
      <p>
        The standard deduction is $21,900 if you’re filing as head of household.
        Consider using itemized deductions if your qualifying expenses are
        higher than $21,900.
      </p>
    );
  } else {
    deductionMessage = (
      <p>
        The standard deduction is $14,600 if you’re single or married filing
        separately. Consider using itemized deductions if your qualifying
        expenses are higher than $14,600.
      </p>
    );
  }

  const incomePercentage = () => {
    return (
      (Number(formValues.income1) /
        (Number(formValues.income1) + Number(formValues.income2))) *
      100
    );
  };

  const primaryPercentage = incomePercentage().toFixed(0);
  const spouseIncomePercentage = () => {
    return (
      (Number(formValues.income2) /
        (Number(formValues.income1) + Number(formValues.income2))) *
      100
    );
  };
  const spousePercentage = spouseIncomePercentage().toFixed(0);

  const moveForward = (evt) => {
    evt.preventDefault();

    const primaryItemizeAmount =
      (primaryPercentage / 100) * deductionType.totalAmountToItemize;
    const spouseItemizeAmount =
      (spousePercentage / 100) * deductionType.totalAmountToItemize;

    const newDeductionType = {
      ...deductionType,
      amountToItemizeSpouse: spouseItemizeAmount.toFixed(0),
      amountToItemize: primaryItemizeAmount.toFixed(0),
    };

    setDeductionType(newDeductionType);
    handleChange(newDeductionType);
    setShowSpouseSection(true);
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className={styles.questionContainer}>
        <h3>
          Deduction Type
          <img
            onClick={deductionAlert}
            alt="question mark"
            className={styles.question}
            src={questionGraphic}
          />
        </h3>
        <p>{deductionMessage}</p>
        <div>
          <input
            type="radio"
            id="standard"
            name="deductionType"
            value="standard"
            checked={deductionType.deductionType === "standard"}
            onChange={makeChange}
          />
          <label htmlFor="standard">Standard Deduction</label>
          <br />
          <input
            type="radio"
            id="itemized"
            name="deductionType"
            value="itemized"
            checked={deductionType.deductionType === "itemized"}
            onChange={makeChange}
          />
          <label htmlFor="itemized">Itemized Deductions</label>
          <br />
          {validationError && (
            <div className={styles.error}>{validationError}</div>
          )}
          <div className={styles.nextButton}>
            <button type="submit">NEXT</button>
          </div>
        </div>
      </div>
      {open && (
        <div className={styles.questionContainer}>
          <h3>Itemized Deductions</h3>
          <div className={styles.alignLeft}>
            <label htmlFor="totalAmountToItemize">
              Enter an estimate of your itemized deductions. Such deductions may
              include qualifying home mortgage interest, charitable
              contributions, state and local taxes (up to $10,000), and medical
              expenses in excess of 7.5% of your annual wages.
            </label>
            <br />
            <br />
            <input
              name="totalAmountToItemize"
              value={deductionType.totalAmountToItemize}
              onChange={makeChange}
              onFocus={(e) => {
                e.target.value = "";
              }}
            />

            {formValues.taxFilingStatus === "married" ? (
              <div className={styles.nextButton}>
                <button onClick={(e) => moveForward(e)}>NEXT</button>
              </div>
            ) : (
              <div className={styles.nextButton}>
                <button type="submit">NEXT</button>
              </div>
            )}
          </div>
        </div>
      )}

      {showSpouseSection && (
        <div className={styles.questionContainer}>
          <h3>Allocate Itemized Deductions</h3>
          <div className={styles.alignLeft}>
            <br />

            <label htmlFor="amountToItemize">
              {`Amount to itemize on your Form W-4. We suggest
                  allocating according to your percentage of total income or 
                  ${primaryPercentage}%. That would be $${
                (primaryPercentage / 100) * deductionType.totalAmountToItemize
              } for the primary taxpayer.`}
            </label>
            <br />
            <br />
            <input
              name="amountToItemize"
              value={deductionType.amountToItemize}
              onChange={(e) =>
                setDeductionType({
                  ...deductionType,
                  amountToItemize: e.target.value,
                })
              }
              onFocus={(e) => {
                e.target.value = "";
              }}
            />
          </div>

          <div className={styles.alignLeft}>
            <br />

            <label htmlFor="amountToItemizeSpouse">
              {`Amount to itemize on your spouse's Form W-4. We suggest
                  allocating according to your spouse's percentage of total income or 
                  ${spousePercentage}%. That would be $${
                (spousePercentage / 100) * deductionType.totalAmountToItemize
              } for the secondary taxpayer.`}
            </label>
            <br />
            <br />
            <input
              name="amountToItemizeSpouse"
              value={deductionType.amountToItemizeSpouse}
              onChange={(e) =>
                setDeductionType({
                  ...deductionType,
                  amountToItemizeSpouse: e.target.value,
                })
              }
              onFocus={(e) => {
                e.target.value = "";
              }}
            />
          </div>
          <div className={styles.nextButton}>
            <button type="submit">NEXT</button>
          </div>
        </div>
      )}
    </form>
  );
}
