import React, { useState, useRef } from "react";
import ReactGA from "react-ga4";
import SpModal from "sp-modal";
import questionGraphic from "./images/question-mark-circle-4-128.png";
import videoSrc from "./images/4303-children.mp4";
import picSrc from "./images/children-thumbnail.png";
import styles from "./Interview.module.css";

export default function Children({
  childrenCloseButton,
  handleChange,
  initialValues,
}) {
  const [children, setChildren] = useState({});
  const [modalVisible, setModalVisible] = useState(false);

  const openModal = () => {
    setModalVisible(true);
  };
  const handleClose = () => {
    setModalVisible(false);
  };
  const handleVideoEnd = () => {
    setTimeout(() => {
      setModalVisible(false);
    }, 3000); // Set to 3 seconds after the video ends
  };
  const videoRef = useRef(null);
  const childrenAlert = () => {
    alert(
      "To qualify for the child tax credit, the child must be under age 17 as of December 31, must be your dependent who generally lives with you for more than half the year, and must have the required social security number."
    );
  };

  const makeChange = (evt) => {
    setChildren({
      ...children,
      [evt.target.name]: evt.target.value,
    });
  };

  const handleSubmit = (evt) => {
    evt.preventDefault();
    handleChange(children);
    ReactGA.event({
      category: "User",
      action: "Completed number of children step",
    });
    childrenCloseButton();
  };

  return (
    <div className={styles.interviewContainer}>
      <form onSubmit={handleSubmit}>
        {/* start of number of children */}
        {initialValues.processSpouseW4 ? (
          <div>
            <div className={styles.questionContainer}>
              <h3>
                Number of children{" "}
                <span className={styles.smaller}>(under age 17)</span>
                <img
                  onClick={childrenAlert}
                  alt="question mark"
                  className={styles.question}
                  src={questionGraphic}
                />
              </h3>
              <div className={styles.formContainer}>
                <div className={styles.columnContainer}>
                  <div>
                    <p>On Spouse 1's Form W-4</p>
                    <input
                      name="children"
                      defaultValue={initialValues.children}
                      onChange={makeChange}
                      onFocus={(e) => {
                        e.target.value = "";
                      }}
                    />
                  </div>
                  <div>
                    <p>On Spouse 2's or second W-4</p>
                    <input
                      name="childrenSpouse"
                      defaultValue={initialValues.childrenSpouse}
                      onChange={makeChange}
                      onFocus={(e) => {
                        e.target.value = "";
                      }}
                    />
                  </div>
                </div>
                <img
                  className={styles.thumbnail}
                  src={picSrc}
                  alt="video thumbnail"
                  onClick={openModal}
                  style={{ cursor: "pointer" }}
                />
              </div>
              <div className={styles.nextButton}>
                <button type="submit">NEXT</button>
              </div>
            </div>
          </div>
        ) : (
          <div>
            <div className={styles.questionContainer}>
              <h3>
                Number of children{" "}
                <span className={styles.smaller}>(under age 17)</span>
                <img
                  onClick={childrenAlert}
                  alt="question mark"
                  className={styles.question}
                  src={questionGraphic}
                />
              </h3>
              <input
                name="children"
                defaultValue={initialValues.children}
                onChange={makeChange}
                onFocus={(e) => {
                  e.target.value = "";
                }}
              />
            </div>
            <div className={styles.nextButton}>
              <button type="submit">NEXT</button>
            </div>
          </div>
        )}
      </form>
      <SpModal
        visible={modalVisible}
        closeHandler={handleClose}
        className="sp-modal__wrapper sp-modal"
        animation="fade"
      >
        {modalVisible && (
          <video
            ref={videoRef}
            src={videoSrc}
            controls
            autoPlay
            onEnded={handleVideoEnd}
            style={{ width: "100%" }}
          />
        )}
      </SpModal>
    </div>
  );
}
