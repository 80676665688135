import React, { useState, useEffect } from "react";
import ReactGA from "react-ga4";
import styles from "./Interview.module.css";
import "./modal.css";

export default function OrderDetails({
  orderDetailsCloseButton,
  handleChange,
  formValues,
  onApplyPromoCode, // New prop to apply promo code and recalculate total
  onUpdateTotal, // New prop to send the total price back to Interview.js
}) {
  const [orderInfo, setOrderInfo] = useState(formValues);
  const [promoCode, setPromoCode] = useState(""); // Track the entered promo code
  const [promoError, setPromoError] = useState(""); // Error message for invalid promo code
  const [discount, setDiscount] = useState(0); // Discount applied from promo code
  const [totalPrice, setTotalPrice] = useState(2.99); // Default order total

  // Calculate order total based on form values
  const orderTotal = () => {
    if (
      formValues.processSpouseW4 === true &&
      formValues.addAccountantForm === true
    )
      return 6.99;
    else if (
      formValues.processSpouseW4 === true ||
      formValues.addAccountantForm === true
    )
      return 4.99;
    else return 2.99;
  };

  // Update total price dynamically and pass it to Interview.js
  const updateTotalPrice = () => {
    const baseTotal = orderTotal(); // Get the base total
    const discountedTotal = baseTotal - (baseTotal * discount) / 100; // Apply discount if any
    setTotalPrice(discountedTotal); // Set the total price state
    onUpdateTotal(discountedTotal); // Send the updated total to Interview.js
  };

  useEffect(() => {
    updateTotalPrice(); // Recalculate price when formValues or discount changes
  }, [formValues, discount]);

  const handleYesSpouse = () => {
    const processSpouseW4 = formValues.processSpouseW4;
    handleChange({ processSpouseW4: !processSpouseW4 });
  };

  const handleYesReport = () => {
    const addAccountantForm = formValues.addAccountantForm;
    handleChange({ addAccountantForm: !addAccountantForm });
  };

  const makeChange = (evt) => {
    setOrderInfo({ ...orderInfo, [evt.target.name]: evt.target.value });
  };

  const handlePromoCode = async (evt) => {
    evt.preventDefault();
    try {
      const response = await fetch(
        "https://stripe-backend-jtzm.onrender.com/apply-promo-code",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ promoCode }), // Send promo code to backend
        }
      );

      const data = await response.json();

      if (data.error) {
        setPromoError("Invalid promo code."); // Handle invalid promo code
        setDiscount(0); // Reset discount to 0 if invalid
      } else {
        setPromoError(""); // Clear error
        setDiscount(data.discount); // Set the discount received from backend
        updateTotalPrice(); // Recalculate total price after applying discount
        // keeping track of promo code usage in localstorage
        let promoCodeUsage = localStorage.getItem("promoCodeUsage") || 0;
        if (promoCodeUsage >= 2) {
          console.log("Promo code has been used the maximum number of times.");
          return;
        }
        promoCodeUsage++;
        localStorage.setItem("promoCodeUsage", promoCodeUsage);
        localStorage.setItem("reviewClicksPromo", 0);
        ReactGA.event({
          category: "User",
          action: "Used a promo code",
        });
      }
    } catch (error) {
      setPromoError("Error applying promo code."); // Handle network errors
    }
  };

  const handleSubmit = (evt) => {
    evt.preventDefault();
    handleChange(orderInfo);
    onApplyPromoCode(promoCode, discount); // Pass promo code and discount to the parent component
    orderDetailsCloseButton();
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className={styles.questionContainer}>
          <h3>Order Details</h3>
          <p>The first Form W-4 is $2.99</p>
          <div onClick={makeChange}>
            <input
              type="checkbox"
              id="processSpouseW4"
              name="processSpouseW4"
              checked={formValues.processSpouseW4 === true}
              onChange={handleYesSpouse}
            />
            <label htmlFor="processSpouseW4">
              Print your spouse's Form W-4 (add $2)
            </label>
            <br />
            <input
              type="checkbox"
              id="addAccountantForm"
              name="addAccountantForm"
              checked={formValues.addAccountantForm === true}
              onChange={handleYesReport}
            />
            <label htmlFor="addAccountantForm">
              Print a report for your accountant (add $2)
            </label>
            <br />

            <p>{`Order Total: $${totalPrice.toFixed(2)}`}</p>
          </div>

          {/* Promo Code Input */}
          <div>
            <input
              type="text"
              placeholder="Enter promo code"
              value={promoCode}
              onChange={(e) => setPromoCode(e.target.value)}
              className={styles.promoCodeInput}
              style={{ marginRight: "10px", borderRadius: "15px" }}
            />
            <button onClick={handlePromoCode}>Apply Promo Code</button>
            {promoError && <p style={{ color: "red" }}>{promoError}</p>}
          </div>
        </div>
      </form>
    </>
  );
}
