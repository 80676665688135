import React, { useState } from "react";
import ReactGA from "react-ga4";
import questionGraphic from "./images/question-mark-circle-4-128.png";
import * as Yup from "yup";

import styles from "./Interview.module.css";

const validationSchema = Yup.object().shape({
  income1: Yup.string().required(
    "Income for Spouse 1 is required. Be sure to indicate the paycheck frequency for each income you enter."
  ),
});

export default function Income({
  incomeCloseButton,
  handleChange,
  initialValues,
}) {
  const [income, setIncome] = useState({
    paycheckFrequency: "",
    paycheckFrequencySpouse: "",
  });
  const [validationError, setValidationError] = useState(null);

  const incomeAlert = () => {
    alert(
      "Input your estimated annual wages, net of 401k contributions and medical savings account contributions."
    );
  };
  const makeChange = (evt) => {
    setIncome({
      ...income,
      [evt.target.name]: evt.target.value,
    });
    setValidationError(null);
  };

  const handleSubmit = async (evt) => {
    evt.preventDefault(); // Prevent form submission

    try {
      // Validate the form using Yup
      await validationSchema.validate(income, { abortEarly: false });

      // If validation passes, proceed with form submission
      handleChange(income); // Pass income object to handleChange

      ReactGA.event({
        category: "User",
        action: "Completed income step",
      });

      incomeCloseButton(); // Close the form or go to next step
    } catch (error) {
      // Handle validation error
      setValidationError(error.message); // Show the error message
    }
  };

  return (
    <div className={styles.questionContainer}>
      <div onClick={makeChange}>
        <form onSubmit={handleSubmit}>
          <h3>
            Income
            <img
              onClick={incomeAlert}
              alt="question mark"
              className={styles.question}
              src={questionGraphic}
            />
          </h3>
          <div>
            <p>Spouse 1 estimated annual wages:</p>
            <input
              name="income1"
              defaultValue={initialValues.income1}
              onChange={makeChange}
              onFocus={(e) => {
                e.target.value = "";
              }}
            />
            {validationError && (
              <div className={styles.error}>{validationError}</div>
            )}
            <p>Pay frequency:</p>
            <input
              type="radio"
              id="weekly"
              name="paycheckFrequency"
              value="weekly"
              checked={
                income.paycheckFrequency === "weekly" ||
                initialValues.paycheckFrequency === "weekly"
              }
              onChange={makeChange}
            />
            <label htmlFor="weekly">Weekly</label>
            <br />
            <input
              type="radio"
              id="biweekly"
              name="paycheckFrequency"
              value="biweekly"
              checked={
                income.paycheckFrequency === "biweekly" ||
                initialValues.paycheckFrequency === "biweekly"
              }
              onChange={makeChange}
            />
            <label htmlFor="biweekly">Every 2 weeks</label>
            <br />
            <input
              type="radio"
              id="bimonthly"
              name="paycheckFrequency"
              value="bimonthly"
              checked={
                income.paycheckFrequency === "bimonthly" ||
                initialValues.paycheckFrequency === "bimonthly"
              }
              onChange={makeChange}
            />
            <label htmlFor="bimonthly">Twice per month</label>
            <br />
            <input
              type="radio"
              id="monthly"
              name="paycheckFrequency"
              value="monthly"
              checked={
                income.paycheckFrequency === "monthly" ||
                initialValues.paycheckFrequency === "monthly"
              }
              onChange={makeChange}
            />
            <label htmlFor="bimonthly">Monthly</label>

            <p>
              Spouse 2's estimated annual wages (or wages from Spouse 1's second
              job):
            </p>

            <input
              name="income2"
              defaultValue={initialValues.income2}
              onChange={makeChange}
              onFocus={(e) => {
                e.target.value = "";
              }}
            />

            <p>Pay frequency:</p>
            <input
              type="radio"
              id="weekly"
              name="paycheckFrequencySpouse"
              value="weekly"
              checked={
                income.paycheckFrequencySpouse === "weekly" ||
                initialValues?.paycheckFrequencySpouse === "weekly"
              }
              onChange={makeChange}
            />
            <label htmlFor="weekly">Weekly</label>
            <br />
            <input
              type="radio"
              id="biweekly"
              name="paycheckFrequencySpouse"
              value="biweekly"
              checked={
                income.paycheckFrequencySpouse === "biweekly" ||
                initialValues?.paycheckFrequencySpouse === "biweekly"
              }
              onChange={makeChange}
            />
            <label htmlFor="biweekly">Every 2 weeks</label>
            <br />
            <input
              type="radio"
              id="bimonthly"
              name="paycheckFrequencySpouse"
              value="bimonthly"
              checked={
                income.paycheckFrequencySpouse === "bimonthly" ||
                initialValues?.paycheckFrequencySpouse === "bimonthly"
              }
              onChange={makeChange}
            />
            <label htmlFor="bimonthly">Twice per month</label>
            <br />
            <input
              type="radio"
              id="monthly"
              name="paycheckFrequencySpouse"
              value="monthly"
              checked={
                income.paycheckFrequencySpouse === "monthly" ||
                initialValues?.paycheckFrequencySpouse === "monthly"
              }
              onChange={makeChange}
            />
            <label htmlFor="bimonthly">Monthly</label>
          </div>
          <div className={styles.nextButton}>
            <button onClick={handleSubmit}>NEXT</button>
          </div>
        </form>
      </div>
    </div>
  );
}
