import React, { useState } from "react";
import ReactGA from "react-ga4";
import questionGraphic from "./images/question-mark-circle-4-128.png";
import styles from "./Interview.module.css";
import SpModal from "sp-modal";
import * as Yup from "yup";

import "./modal.css";

const validationSchema = Yup.object().shape({
  taxFilingStatus: Yup.string().required("Tax filing status is required"),
});

export default function FilingStatus({
  statusCloseButton,
  handleChange,
  initialValues,
}) {
  const [taxFilingStatus, setTaxFilingStatus] = useState(initialValues);
  const [modalVisible, setModalVisible] = useState(false);
  const [validationError, setValidationError] = useState(null);

  const handleClose = () => {
    setModalVisible(false);
    statusCloseButton();
  };

  const handleYes = () => {
    const processSpouseW4 = true;
    handleChange({ processSpouseW4 });
    ReactGA.event({
      category: "User",
      action: "Married user said yes to processSpouseW4",
    });
    handleClose();
  };

  const statusAlert = () => {
    alert(
      "Check your anticipated filing status. This will determine the standard deduction and tax rates used to compute your withholding."
    );
  };

  const makeChange = (evt) => {
    setTaxFilingStatus(evt.target.value);
    setValidationError(null);
  };

  const handleSubmit = async (evt) => {
    evt.preventDefault();
    ReactGA.event({
      category: "User",
      action: "Hit Next button on Filing Status",
      label: "Hit Next button on Filing Status",
    });
    try {
      if (taxFilingStatus) {
        handleChange({ taxFilingStatus });

        if (taxFilingStatus === "married") {
          setModalVisible(true);
        } else {
          statusCloseButton();
        }
      } else {
        await validationSchema.validate({
          taxFilingStatus,
        });
        handleChange({ taxFilingStatus });

        if (taxFilingStatus === "married") {
          setModalVisible(true);
        } else {
          statusCloseButton();
        }
      }
    } catch (error) {
      setValidationError(error.message);
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className={styles.questionContainer}>
          <h3>
            Filing Status
            <img
              onClick={statusAlert}
              alt="question mark"
              className={styles.question}
              src={questionGraphic}
            />
          </h3>
          <div onClick={makeChange}>
            <input
              type="radio"
              id="single"
              name="taxFilingStatus"
              value="single"
              checked={taxFilingStatus === "single"}
              onChange={makeChange}
            />
            <label htmlFor="single">Single or Married Filing Separately</label>
            <br />
            <input
              type="radio"
              id="married"
              name="taxFilingStatus"
              value="married"
              checked={taxFilingStatus === "married"}
              onChange={makeChange}
            />
            <label htmlFor="married">Married</label>
            <br />

            <input
              type="radio"
              id="household"
              name="taxFilingStatus"
              value="household"
              checked={taxFilingStatus === "household"}
              onChange={makeChange}
            />
            <label htmlFor="household">Head of Household</label>
          </div>
          {validationError && (
            <div className={styles.error}>{validationError}</div>
          )}
          <div className={styles.nextButton}>
            <button type="submit">NEXT</button>
          </div>
        </div>
      </form>

      <SpModal
        visible={modalVisible}
        closeHandler={handleClose}
        className="sp-modal__wrapper sp-modal"
      >
        <div>
          <p>
            For married filers – we recommend both spouses complete a Form W-4.
            Would you like a second Form W-4 for $2?
          </p>
        </div>
        <div className={styles.buttonContainer}>
          <button
            className={styles.buttonSpacing}
            style={{ paddingLeft: "40px", paddingRight: "40px" }}
            onClick={() => handleYes(true)}
          >
            YES!
          </button>
          <button
            onClick={handleClose}
            style={{ backgroundColor: "lightgray" }}
          >
            <span style={{ fontSize: "20px" }}>No Thanks</span>
          </button>
        </div>
      </SpModal>
    </>
  );
}
