import React from "react";
import ReactGA from "react-ga4";
import {
  useStripe,
  useElements,
  PaymentElement,
} from "@stripe/react-stripe-js";
import SpModal from "sp-modal";
import styles from "./Interview.module.css";

export default function PaymentModal({
  onClose,
  formValues,
  visible,
  onPaymentSuccess,
}) {
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event) => {
    event.preventDefault();
    ReactGA.event({
      category: "User",
      action: "Hit the Finish and Pay button",
    });

    if (!stripe || !elements) return;

    const { error, paymentIntent } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: window.location.href,
      },
      redirect: "if_required",
    });

    if (error) {
      console.error(error.message);
    } else if (paymentIntent && paymentIntent.status === "succeeded") {
      // Payment succeeded, now fill the PDF with the values in formValues
      onPaymentSuccess(paymentIntent.status);
      onClose();
    }
  };

  return (
    <SpModal
      visible={true}
      onRequestClose={onClose}
      closeHandler={onClose}
      contentLabel="Payment Modal"
      ariaHideApp={false}
    >
      <form onSubmit={handleSubmit}>
        <PaymentElement allow_promotion_codes="true" />
        <div className={styles.payNowButton}>
          <button type="submit" disabled={!stripe}>
            Pay Now
          </button>
        </div>
      </form>
    </SpModal>
  );
}
